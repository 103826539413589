import styled, { css } from 'styled-components';
import { FC, MouseEvent } from 'react';
import { useDecision } from '@optimizely/react-sdk';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';

import {
  OrderStatusEnums,
  OrderStylizationTypeEnums,
} from 'order/enums/orderEnums';

import { ButtonIcon } from 'shared/components/Button';
import { H3 } from 'shared/components/Typography';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { mineShaft } from 'shared/config/Colors';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';

import { OrderLogsTabEnum } from '../../enums/OrderLogsTabEnum';

interface OrderLogsHeaderProp {
  currentTab: OrderLogsTabEnum | null;
  handleArrowClick?: () => void;
  handleChangeCurrentTab?: (
    e: MouseEvent<HTMLHeadingElement>,
    tab: OrderLogsTabEnum
  ) => void;
}

const OrderLogsHeaderContainer = styled(Wrapper)<{ active: boolean }>`
  /* cursor: pointer; */
  translate: 300ms ease;
  border-radius: 16px 16px 0px 0px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.wildSandTwo};
    `}
`;

const OrderLogsTab = styled(H3)<{ active: boolean }>`
  transition: 300ms;
  padding: 26px 0 26px 32px;
  min-width: 200px;
  color: ${({ theme }) => theme.poloBlue};
  cursor: pointer;

  &:first-child {
    border-radius: 16px 0 0 0;
  }

  &:hover {
    color: ${({ theme }) => theme.kashmirBlue};
  }

  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.kashmirBlue};
      background-color: ${theme.white};
    `}
`;

const OrderLogsHeader: FC<OrderLogsHeaderProp> = ({
  handleArrowClick,
  handleChangeCurrentTab,
  currentTab,
}) => {
  const [ackChangelog] = useDecision('ack_change_log');

  const isOrderSubmitted = useIsOrderInStatus({
    status: OrderStatusEnums['Not Submitted'],
    greaterThen: true,
  });

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  return (
    <OrderLogsHeaderContainer flex middle active={currentTab !== null}>
      {!isOrderSalesMaterial && (
        <OrderLogsTab
          active={currentTab === OrderLogsTabEnum.HINTS}
          onClick={(e) => handleChangeCurrentTab?.(e, OrderLogsTabEnum.HINTS)}
        >
          {OrderLogsTabEnum.HINTS}
        </OrderLogsTab>
      )}

      {isOrderSubmitted && (
        <OrderLogsTab
          active={currentTab === OrderLogsTabEnum.ORDER_CHANGE_LOG}
          onClick={(e) =>
            handleChangeCurrentTab?.(e, OrderLogsTabEnum.ORDER_CHANGE_LOG)
          }
        >
          {OrderLogsTabEnum.ORDER_CHANGE_LOG}
        </OrderLogsTab>
      )}

      {ackChangelog.enabled && (
        <OrderLogsTab
          active={currentTab === OrderLogsTabEnum.ACK_CHANGE_LOG}
          onClick={(e) =>
            handleChangeCurrentTab?.(e, OrderLogsTabEnum.ACK_CHANGE_LOG)
          }
        >
          {OrderLogsTabEnum.ACK_CHANGE_LOG}
        </OrderLogsTab>
      )}

      <Wrapper mlAuto>
        <ButtonIcon iconColor={mineShaft} onClick={handleArrowClick}>
          <ArrowUpIcon />
        </ButtonIcon>

        <Spacer w="40px" />
      </Wrapper>
    </OrderLogsHeaderContainer>
  );
};

export default OrderLogsHeader;

import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { RootState } from 'store';

import { ShipViaOption } from 'curtain/interfaces/ShipVia';

import { dealershipActions } from 'overview/dealership/store/dealershipActions';

import { Collaborator } from 'order/interfaces/Collaborator';
import { collaboratorAccessEnums } from 'order/enums/collaboratorAccessEnums';
import { CollaboratorResponse } from 'order/interfaces/CollaboratorResponse';
import { IOrderDeleteStyleRequest } from 'order/interfaces/IOrderDeleteStyleRequest';
import { Job, GetJobRequest, CreateJobRequest } from 'order/interfaces/Job';
import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { OrderCollaboratorsRequest } from 'order/interfaces/OrderCollaboratorsRequest';
import { OrderExistingShippingAddresRequest } from 'order/interfaces/OrderShippingAddress';
import { ProductLine } from 'order/interfaces/ProductLine';
import { SearchCollaboratosRequest } from 'order/interfaces/SearchCollaborators';
import { setStylesForImport } from 'order/wizard/import/store/ImportActions';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { UpchargeDifference } from 'order/interfaces/UpchargeDifference';

import {
  OrderPriceReviewSM,
  OrderStylePriceReview,
} from 'order/wizard/orderStyles/interface/OrderPriceReview';

import {
  Order,
  OrderLockInfo,
  OrderStatusEditLog,
} from 'order/interfaces/Order';

import {
  OrderStatusEnums,
  OrderTransportationTypeEnums,
} from 'order/enums/orderEnums';

import {
  addNewJob,
  ChangeOrderGradeRequest,
  DeleteOrderRequest,
  deselectCollaborator,
  ExportPDFRequest,
  GetDealershipPreferencesRequest,
  GetLockInfoOnOrderReqest,
  GetOrderLineItemCLValuesRequest,
  GetOrderLineItemsRequest,
  GetOrderPriceReviewRequest,
  GetOrderRequest,
  GetOrderStyleRequest,
  getUpchargeDifferences as GetUpchargeDifferencesRequest,
  KeepAliveOrderEditSessionRequest,
  LockOrderForEditRequest,
  orderActions,
  RemoveOrderCollaboratorRequest,
  replaceOrderStatus,
  SaveShipViaOptionRequest,
  setDealershipPreferences,
  setDealerships,
  setDealershipUsers,
  setJobs,
  setLockInfoOnOrder,
  setNumberOfUnresolvedTopics,
  setOrder,
  setOrderIsLocked,
  setOrderIsValid,
  setOrderLineItems,
  setOrderPriceReview,
  setOrderPriceReviewSM,
  setOrderStatus,
  setOrderStyles,
  setSearchedCollaborators,
  setShippingAddress,
  setStatusEditLog,
  setStyleCancelRequested,
  setUpchargeDifferences,
  setUpchargesOverriden,
  StyleCancelRequestRequest,
  SubmitOrderRequest,
  UnlockOrderForEditRequest,
  updateOrderLineItemCLValues,
  UpdateOrderStatusRequest,
} from 'order/store/orderActions';

import { Action } from 'shared/interface/Action';
import { BaseField } from 'shared/interface/BaseField';
import { CustomAddressRequestObject } from 'shared/interface/CustomAddressRequestObject';
import { Dealership } from 'shared/interface/Dealership';
import { IFileData } from 'shared/interface/IFile';
import { PaginatedItems } from 'shared/interface/PaginatedItems';
import { PriceVariablesGlobal } from 'shared/interface/PriceVariables';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import { User } from 'shared/interface/User';
import ApiService from 'shared/services/api.service';
import UtilService from 'shared/services/util.service';

function* getOrderJob(action: Action<string, Job>) {
  try {
    const job: Job = yield call(
      ApiService.get,
      `/api/order/jobs/${action.payload}`
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, job);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderJobs(action: Action<GetJobRequest>) {
  try {
    const { dealershipId, itemsPerPage, name, page, append } = action.payload!;

    const params = new URLSearchParams();

    params.append('dealershipId', dealershipId);
    params.append('page', page.toString());
    params.append('itemsPerPage', itemsPerPage.toString());

    if (name) {
      params.append('name', name);
    }

    const jobs: PaginatedItems<Job> = yield call(
      ApiService.get,
      '/api/order/jobs',
      {
        params,
      }
    );

    yield put(setJobs(jobs, append));

    if (action.onSuccess) {
      yield call(
        action.onSuccess,
        UtilService.mapJobsToSelectOptions(jobs.items)
      );
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* createOrderJob(action: Action<CreateJobRequest>) {
  try {
    const jobId: string = yield call(
      ApiService.post,
      '/api/order/jobs',
      action.payload
    );

    if (action.payload) {
      const newJob: Job = {
        id: jobId,
        name: action.payload?.name,
      };

      yield put(addNewJob(newJob));
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, jobId);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* createOrder(action: Action<Order>) {
  try {
    const orderId: string = yield call(
      ApiService.post,
      '/api/order/orders',
      action.payload
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, orderId);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateOrder(action: Action<Order>) {
  try {
    const { id, ...rest } = action.payload!;

    yield call(ApiService.put, `/api/order/orders/${id}`, rest);

    if (action.onSuccess) {
      yield call(action.onSuccess, id);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getUpchargeDifferences(action: Action<string>) {
  try {
    const upchargeDifferences: UpchargeDifference[] = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload}/upcharge-differences`
    );

    // FOR DEBUGING PURPOSES
    // upchargeDifferences.push(
    //   {
    //     finishColorUpchargeDifferenceResponse: {
    //       fieldName: 'Finish Color',
    //       currentUpcharge: 15,
    //       latestUpcharge: 10,
    //     },
    //     id: '1',
    //     number: 1,
    //     name: 'Branko Style',
    //     woodMaterialUpchargeDifferenceResponse: {
    //       fieldName: 'Wood',
    //       currentUpcharge: 10,
    //       latestUpcharge: 20,
    //     },
    //   },
    //   {
    //     finishColorUpchargeDifferenceResponse: {
    //       fieldName: 'Finish Color',
    //       currentUpcharge: 15,
    //       latestUpcharge: 10,
    //     },
    //     id: '2',
    //     number: 2,
    //     name: 'Milos Style',
    //     woodMaterialUpchargeDifferenceResponse: {
    //       fieldName: 'Wood',
    //       currentUpcharge: 10,
    //       latestUpcharge: 20,
    //     },
    //   }
    // );

    yield put(setUpchargeDifferences(upchargeDifferences));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrder(action: Action<GetOrderRequest>) {
  try {
    const { orderId, isAsap, isDuplicate } = action.payload!;

    const order: Order = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}`
    );

    // get pricing variables from redux
    const priceVariablesGlobalStored: PriceVariablesGlobal | null =
      yield select(
        (state: RootState) => state.dealershipReducer.priceVariablesGlobal
      );

    // if does not exists when order is loaded, pull them
    if (!priceVariablesGlobalStored) {
      const priceVariablesGlobal: PriceVariablesGlobal = yield call(
        ApiService.get,
        `/api/administration/dealerships/${order.job?.dealershipId}/price-variables`
      );

      yield put({
        type: dealershipActions.SET_DEALERSHIP_GLOBAL_PRICE_VARIABLES,
        payload: priceVariablesGlobal,
      });
    }

    const collaboratorsResponse: CollaboratorResponse[] = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/collaborators`
    );

    const shouldGetAddress =
      order.transportationType !== +OrderTransportationTypeEnums.WILL_CALL &&
      order.transportationType !== +OrderTransportationTypeEnums.NONE;

    const address: ShippingAddress | null = shouldGetAddress
      ? yield call(
          ApiService.get,
          `/api/order/orders/${orderId}/shipping-address`
        )
      : null;

    const collaborators: Collaborator[] = collaboratorsResponse.map(
      (collaborator) => ({
        ...collaborator.user,
        id: collaborator.userId,
        collaboratorType: collaborator.collaboratorAccess.id,
      })
    );

    const loggedInUserId: string = yield select(
      (state: RootState) => state.authReducer.user?.id
    );

    const isCurrentUserOwner = collaborators.some(
      (x) =>
        x.id === loggedInUserId &&
        x.collaboratorType === collaboratorAccessEnums.OWNER
    );

    const isCurrentUserEditor = collaborators.some(
      (x) =>
        x.id === loggedInUserId &&
        x.collaboratorType === collaboratorAccessEnums.EDITOR
    );

    const isCurrentUserViewer = collaborators.some(
      (x) =>
        x.id === loggedInUserId &&
        x.collaboratorType === collaboratorAccessEnums.VIEWER
    );

    const getUpchargeDifferencesRequest = GetUpchargeDifferencesRequest(
      order.id!
    );

    yield call(getUpchargeDifferences, getUpchargeDifferencesRequest);

    yield put(
      setOrder({
        order: {
          ...order,
          dealershipId: order.job?.dealershipId ?? 'mockedId',
        },
        collaborators: isAsap || isDuplicate ? null : collaborators,
        address: !isAsap ? address : null,
        isCurrentUserOwner,
        isCurrentUserEditor,
        isCurrentUserViewer,
      })
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, order);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* deleteOrder(action: Action<DeleteOrderRequest>) {
  try {
    yield call(
      ApiService.delete,
      `/api/order/orders/${action.payload?.orderId}`
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, action.payload?.orderId);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveOrderExistingShippingAddress(
  action: Action<OrderExistingShippingAddresRequest>
) {
  try {
    const { orderId, editMode, isAsapOrDuplicate, ...rest } = action.payload!;

    yield call(
      !editMode || isAsapOrDuplicate ? ApiService.post : ApiService.put,
      `/api/order/orders/${orderId}/shipping-address/existing`,
      rest
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* createNonExistingShippingAddress(
  action: Action<CustomAddressRequestObject>
) {
  try {
    const { orderId, dealershipId, isAsapOrDuplicate, editMode, ...rest } =
      action.payload!;

    const id: string = yield call(
      !editMode || isAsapOrDuplicate ? ApiService.post : ApiService.put,
      `/api/order/orders/${orderId}/shipping-address/new`,
      { ...rest, dealershipId }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, id);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* searchCollaborators(action: Action<SearchCollaboratosRequest>) {
  try {
    const params = new URLSearchParams();
    params.append('searchTerm', action.payload!.searchTerm);

    const collaborators: User[] = yield call(
      ApiService.get,
      `/api/administration/dealerships/${
        action.payload!.dealershipId
      }/users/filtered`,
      {
        params,
      }
    );

    yield put(setSearchedCollaborators(collaborators));

    if (action.onSuccess) {
      yield call(action.onSuccess, collaborators);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveOrderCollaborators(action: Action<OrderCollaboratorsRequest>) {
  try {
    const { orderId, editMode, ...rest } = action.payload!;

    yield call(
      !editMode ? ApiService.post : ApiService.put,
      `/api/order/orders/${orderId}/collaborators`,
      rest
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* removeOrderCollaborator(
  action: Action<RemoveOrderCollaboratorRequest>
) {
  try {
    const { orderId, userId } = action.payload!;

    yield call(
      ApiService.delete,
      `api/order/orders/${orderId}/collaborators/${userId}`
    );

    yield put(deselectCollaborator(userId));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderStyles(action: Action<string>) {
  try {
    const styles: Style[] = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload}/styles`
    );

    const { orderId: importStylesOrderSelected } = yield select(
      (state: RootState) => state.importStylesReducer
    );

    const conversionRequests = styles.map((style) => {
      const queryParams = new URLSearchParams();

      queryParams.append('productLineId', style.productLine.id);

      return call(ApiService.get, '/api/catalog/productlines/conversions', {
        params: queryParams,
      });
    });

    const conversionResponses: [ProductLine[]] = yield all(conversionRequests);

    const stylesForStore = styles.map(
      (styleForStore, index) =>
        ({
          ...styleForStore,
          canConvert: conversionResponses[index].length > 1,
        } as Style)
    );

    if (!importStylesOrderSelected) {
      yield put(setOrderStyles(stylesForStore));
    } else {
      yield put(setStylesForImport(stylesForStore));
    }

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, stylesForStore);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderStyle(action: Action<GetOrderStyleRequest>) {
  try {
    const { orderId, styleId } = action.payload!;

    const style: Style = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/styles/${styleId}`
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, style);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* deleteOrderStyle(action: Action<IOrderDeleteStyleRequest>) {
  try {
    yield call(
      ApiService.delete,
      `/api/order/orders/${action.payload?.orderID}/styles/${action.payload?.styleID}`
    );

    if (action.onSuccess) {
      yield call(action.onSuccess, action.payload?.styleID);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderLineItems(action: Action<GetOrderLineItemsRequest>) {
  try {
    const { orderId, productLineId, isSalesMaterial } = action.payload!;

    const queryParams = new URLSearchParams();

    if (productLineId) {
      queryParams.append('productLineId', productLineId);
    }

    const lineItems: LineItem[] = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/${
        isSalesMaterial ? 'salesmaterial-' : ''
      }lineitems`,
      { params: queryParams }
    );

    const notDeletedLineItems = lineItems?.filter(
      (lineItem) => !lineItem.isDeleted
    );

    yield put(
      setOrderIsValid(
        notDeletedLineItems.every(
          (lineItem) =>
            lineItem.isValid &&
            (lineItem.modifications.length > 0
              ? lineItem.modifications.every((mod) => mod.isValid)
              : true)
        )
      )
    );

    yield put(setOrderLineItems(notDeletedLineItems));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, notDeletedLineItems);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderLineItemCLValues(
  action: Action<GetOrderLineItemCLValuesRequest>
) {
  try {
    const { orderId, productLineId, isSalesMaterial } = action.payload!;

    const queryParams = new URLSearchParams();

    if (productLineId) {
      queryParams.append('productLineId', productLineId);
    }

    const lineItems: LineItem[] = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/${
        isSalesMaterial ? 'salesmaterial-' : ''
      }lineitems`,
      { params: queryParams }
    );

    yield put(
      updateOrderLineItemCLValues({
        isOrderSalesMaterial: isSalesMaterial ?? false,
        lineItems,
      })
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderPriceReview(action: Action<string>) {
  try {
    const orderPriceReview: OrderStylePriceReview[] = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload}/priceReview`
    );

    yield put(setOrderPriceReview(orderPriceReview));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, orderPriceReview);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderPriceReviewSM(action: Action<GetOrderPriceReviewRequest>) {
  try {
    const { orderId, productLineId } = action.payload!;

    const queryParams = new URLSearchParams();

    queryParams.append('productLineId', productLineId);

    const orderPriceReviewSM: OrderPriceReviewSM = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/salesmaterial-pricereview`,
      {
        params: queryParams,
      }
    );

    yield put(setOrderPriceReviewSM(orderPriceReviewSM));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, orderPriceReviewSM);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* submitOrder(action: Action<SubmitOrderRequest>) {
  try {
    yield call(
      ApiService.put,
      `/api/order/orders/${action.payload!.orderId}/submit?isAsap=${
        action.payload?.isAsap
      }
      `
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getUnresolvedTopics(action: Action<string>) {
  try {
    const queryParams = new URLSearchParams();

    queryParams.append('orderId', action.payload!);

    const numberOfTopics: number = yield call(
      ApiService.get,
      `/api/collaboration/topics/number-of-unresolved`,
      { params: queryParams }
    );

    yield put(setNumberOfUnresolvedTopics(numberOfTopics));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDealershipPreferences(
  action: Action<GetDealershipPreferencesRequest>
) {
  const { dealershipId } = action.payload!;

  try {
    const dalershipPrefereces: string[] = yield call(
      ApiService.get,
      `/api/administration/dealerships/${dealershipId}/preferences`
    );

    yield put(setDealershipPreferences(dalershipPrefereces));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getShipViaOptions(action: Action<undefined, SelectOptionProps[]>) {
  try {
    const shipVia: ShipViaOption[] = yield call(
      ApiService.get,
      `/api/order/shipoptions`
    );

    const shipViaOptions = UtilService.mapObjectToSelectOptions(
      shipVia
    ) as SelectOptionProps[];

    if (action.onSuccess) {
      yield call(action.onSuccess, shipViaOptions);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* saveShipViaOption(action: Action<SaveShipViaOptionRequest>) {
  try {
    const { orderId, shipOptionId } = action.payload!;

    yield call(ApiService.put, `/api/order/orders/${orderId}/ship-option`, {
      shipOptionId,
    });

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* changeOrderGrade(action: Action<ChangeOrderGradeRequest>) {
  try {
    yield call(
      ApiService.put,
      `/api/order/orders/${action.payload?.orderId}/grading-option`,
      {
        gradingOption: action.payload?.gradingOption,
      }
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* lockOrderForEdit(action: Action<LockOrderForEditRequest>) {
  try {
    const { orderId } = action.payload!;

    const lockId: string = yield call(
      ApiService.post,
      `/api/order/orders/${orderId}/locks`,
      {}
    );

    // pull out current user's info.
    const { firstName, lastName, imageUrl, id, roles }: User = yield select(
      (state: RootState) => state.authReducer.user
    );

    // create lock info object and set it to the store
    yield put(
      setLockInfoOnOrder({
        id: lockId,
        lockedByUserId: id,
        lockedOnUtc: new Date().toUTCString(),
        modifiedOnUtc: new Date().toUTCString(),
        orderId,
        user: { firstName, lastName, imageUrl, roles },
      } as OrderLockInfo)
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* unlockOrderForEdit(action: Action<UnlockOrderForEditRequest>) {
  try {
    const { orderId, orderLockId } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/locks/${orderLockId}/unlock`,
      {}
    );

    // create lock info object and set it to the store
    yield put(setLockInfoOnOrder(null));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getLockInfoOnOrder(
  action: Action<GetLockInfoOnOrderReqest, OrderLockInfo>
) {
  try {
    const { orderId } = action.payload!;
    const orderLockInfo: OrderLockInfo = yield call(
      ApiService.get,
      `/api/order/orders/${orderId}/locks/active`
    );

    yield put(setLockInfoOnOrder(orderLockInfo));

    if (action.onSuccess) {
      yield call(action.onSuccess, orderLockInfo);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* keepAliveOrderEditSession(
  action: Action<KeepAliveOrderEditSessionRequest>
) {
  try {
    const { orderId, orderLockId } = action.payload!;

    yield call(
      ApiService.put,
      `/api/order/orders/${orderId}/locks/${orderLockId}/keep-alive`,
      {}
    );

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* updateOrderStatus(action: Action<UpdateOrderStatusRequest>) {
  try {
    const { orderId, orderStatusId } = action.payload!;

    yield call(ApiService.put, `/api/order/orders/${orderId}/update-status`, {
      orderStatusId,
    });

    const order: Order = yield select(
      (state: RootState) => state.orderReducer.order
    );

    if (order) {
      yield put(
        replaceOrderStatus({
          id: orderStatusId,
          name: OrderStatusEnums[orderStatusId],
        })
      );
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderStatusEditLog(action: Action<string>) {
  try {
    const statusEditLogs: OrderStatusEditLog[] = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload}/status-edit-log`
    );

    yield put(setStatusEditLog(statusEditLogs));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* styleCancelRequest(action: Action<StyleCancelRequestRequest>) {
  try {
    const { orderId, styleId } = action.payload!;
    yield call(
      ApiService.put,
      `api/order/orders/${orderId}/styles/${styleId}/request-cancellation`,
      {}
    );

    yield put(setStyleCancelRequested(styleId));

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getOrderStatus(action: Action<string>) {
  try {
    const orderStatus: BaseField = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload!}/status`,
      {}
    );

    yield put(setOrderStatus(orderStatus.id));

    if (orderStatus.id > OrderStatusEnums.Received) {
      yield put(setOrderIsLocked(true));
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    if (action.loading) {
      yield call(action.loading, false);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDealerships(action: Action<string>) {
  try {
    const dealerships: Dealership[] = yield call(
      ApiService.get,
      `/api/administration/dealerships/filtered`
    );

    const dealershipOptions = UtilService.mapObjectToSelectOptions(
      dealerships.filter((dealership) => dealership.isActive),
      'id',
      'name'
    ) as SelectOptionProps[];

    yield put(setDealerships(dealershipOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, dealerships);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getDealershipUsers(action: Action<string>) {
  try {
    const dalershipUsers: User[] = yield call(
      ApiService.get,
      `/api/administration/dealerships/${action.payload}/users`
    );

    // logic behind this
    // 1. convert dealership user list to object that its known to ReactSelect.
    // 2. map those options and add user object to each and one of them.

    const dealershipUsersOptions = (
      UtilService.mapObjectToSelectOptions(
        dalershipUsers,
        'id',
        'email'
      ) as SelectOptionProps[]
    ).map((option) => ({
      ...option,
      label: UtilService.getUsersConcatenatedNameOrEmail(
        dalershipUsers.find((user) => option.value === user.id)!
      ),
      user: dalershipUsers.find((user) => option.value === user.id),
    }));

    yield put(setDealershipUsers(dealershipUsersOptions));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* overrideUpcharges(action: Action<string>) {
  try {
    yield call(
      ApiService.put,
      `/api/order/orders/${action.payload}/override-upcharges`
    );

    yield put(setUpchargesOverriden(true));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}
function* exportAsPDF(action: Action<ExportPDFRequest>) {
  const queryParams = new URLSearchParams();

  queryParams.append('noPricing', action.payload!.noPricing.toString());

  try {
    const pdfSnapshot: IFileData = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload?.orderId}/acknowledgements/download-order-snapshot`,
      { params: queryParams }
    );

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess, pdfSnapshot);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

function* getShippingAddress(action: Action<string>) {
  try {
    const address: ShippingAddress | null = yield call(
      ApiService.get,
      `/api/order/orders/${action.payload}/shipping-address`
    );

    yield put(setShippingAddress(address));

    if (action.loading) {
      yield call(action.loading, false);
    }

    if (action.onSuccess) {
      yield call(action.onSuccess);
    }
  } catch (e) {
    yield UtilService.catchErrorHandler(e, action.onFailed);
  }
}

export function* orderSagas() {
  yield takeEvery(orderActions.GET_ORDER_JOB, getOrderJob);
  yield takeEvery(orderActions.GET_ORDER_JOBS, getOrderJobs);
  yield takeEvery(orderActions.CREATE_ORDER_JOB, createOrderJob);
  yield takeEvery(orderActions.CREATE_ORDER, createOrder);
  yield takeEvery(orderActions.UPDATE_ORDER, updateOrder);
  yield takeEvery(orderActions.GET_ORDER, getOrder);
  yield takeEvery(orderActions.DELETE_ORDER, deleteOrder);
  yield takeEvery(orderActions.SEARCH_COLLABORATORS, searchCollaborators);
  yield takeEvery(orderActions.GET_ORDER_STYLES, getOrderStyles);
  yield takeEvery(orderActions.GET_ORDER_STYLE, getOrderStyle);
  yield takeEvery(
    orderActions.SAVE_ORDER_COLLABORATORS,
    saveOrderCollaborators
  );
  yield takeEvery(
    orderActions.REMOVE_ORDER_COLLABORATOR,
    removeOrderCollaborator
  );
  yield takeEvery(
    orderActions.SAVE_ORDER_EXISTING_SHIPPING_ADDRESS,
    saveOrderExistingShippingAddress
  );
  yield takeEvery(
    orderActions.CREATE_NON_EXISTING_SHIPPING_ADDRESS,
    createNonExistingShippingAddress
  );
  yield takeEvery(orderActions.DELETE_ORDER_STYLE, deleteOrderStyle);
  yield takeEvery(orderActions.GET_ORDER_LINE_ITEMS, getOrderLineItems);
  yield takeEvery(
    orderActions.GET_ORDER_LINE_ITEM_CL_VALUES,
    getOrderLineItemCLValues
  );
  yield takeEvery(orderActions.GET_ORDER_PRICE_REVIEW, getOrderPriceReview);
  yield takeEvery(
    orderActions.GET_ORDER_PRICE_REVIEW_SM,
    getOrderPriceReviewSM
  );
  yield takeEvery(orderActions.SUBMIT_ORDER, submitOrder);
  yield takeEvery(orderActions.GET_UNRESOLVED_TOPICS, getUnresolvedTopics);
  yield takeEvery(
    orderActions.GET_DEALERSHIP_PREFERENCES,
    getDealershipPreferences
  );
  yield takeEvery(orderActions.GET_SHIP_VIA_OPTIONS, getShipViaOptions);
  yield takeEvery(orderActions.SAVE_SHIP_VIA_OPTION, saveShipViaOption);
  yield takeEvery(orderActions.CHANGE_ORDER_GRADE, changeOrderGrade);
  yield takeEvery(orderActions.LOCK_ORDER_FOR_EDIT, lockOrderForEdit);
  yield takeEvery(orderActions.UNLOCK_ORDER_FOR_EDIT, unlockOrderForEdit);
  yield takeEvery(orderActions.GET_LOCK_INFO_ON_ORDER, getLockInfoOnOrder);
  yield takeEvery(
    orderActions.KEEP_ALIVE_ORDER_EDIT_SESSION,
    keepAliveOrderEditSession
  );
  yield takeEvery(orderActions.UPDATE_ORDER_STATUS, updateOrderStatus);
  yield takeEvery(orderActions.GET_STATUS_EDIT_LOG, getOrderStatusEditLog);
  yield takeEvery(orderActions.STYLE_CANCEL_REQUEST, styleCancelRequest);
  yield takeEvery(orderActions.GET_ORDER_STATUS, getOrderStatus);
  yield takeEvery(orderActions.GET_DEALERSHIPS, getDealerships);
  yield takeEvery(orderActions.GET_DEALERSHIP_USERS, getDealershipUsers);
  yield takeEvery(
    orderActions.GET_UPCHARGE_DIFFERENCES,
    getUpchargeDifferences
  );
  yield takeEvery(orderActions.OVERRIDE_UPCHARGES, overrideUpcharges);
  yield takeEvery(orderActions.EXPORT_AS_PDF, exportAsPDF);
  yield takeEvery(orderActions.GET_SHIPPING_ADDRESS, getShippingAddress);
}

import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { RootState } from 'store';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  clearDealershipUsers,
  getDealerships,
  getDealershipUsers,
} from 'order/store/orderActions';

import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H2, P } from 'shared/components/Typography';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Spacer, WhiteBox } from 'shared/components/Layout';
import FormError from 'shared/components/FormError';
import { nevada } from 'shared/config/Colors';
import Loader from 'shared/components/Loader';
import { Wrapper } from 'shared/components/Wrapper';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { OrderFormContext } from 'order/wizard/orderForm/OrderFormContext/orderFormContext';
import UtilService from 'shared/services/util.service';
import { zeroGuid } from 'shared/config/Variables';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { useGetAvailableDealershipId } from 'shared/hooks/useGetAvailableDealershipId';
import { getCSRUserList } from 'overview/manageUsers/store/manageUsersActions';

export interface OrderDealershipDealerInfoFields {
  dealership: SelectOptionProps | null;
  dealershipUser: SelectOptionProps | null;
}

const WhiteBoxSection = styled(WhiteBox)`
  margin-bottom: 50px;
  padding: 48px 96px;
`;

const SectionHeading = styled(H2)`
  &:first-child {
    margin-bottom: 30px;
  }

  &:not(:first-child) {
    margin: 30px 0;
  }
`;

const OrderDealershipSelection = () => {
  const dispatch = useAppDispatch();

  const {
    editMode,
    isCSRSelectedDealershipDirty,
    isCSRSelectedDealershipUserDirty,
    onDealershipChange,
    onDealershipUserChange,
    setIsCSRSelectedDealershipDirty,
    setIsCSRSelectedDealershipUserDirty,
  } = useContext(OrderFormContext);

  const [dealershipUsersLoading, setDealershipUsersLoading] = useState(false);

  const [menuOpened, setMenuOpened] = useState(false);

  const [modalOpened, setModalOpened] = useState(false);

  const [dealershipIdModalOpened, setDealershipIdModalOpened] = useState<
    string | null
  >(null);

  const methods = useFormContext<OrderDealershipDealerInfoFields>();

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const dealershipId = useGetAvailableDealershipId();

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const dealershipOptions =
    useSelector((state: RootState) =>
      isUserCSR
        ? state.orderReducer.dealerships
        : state.dealershipReducer.availableDealerships
    ) ?? [];

  const dealershipUsersOptions =
    useSelector((state: RootState) =>
      state.orderReducer.dealershipUsers?.filter((item) => item.user?.enabled)
    ) ?? [];

  const csrUserList = useSelector((state: RootState) =>
    state.manageUsersReducer.users?.map((item) => {
      return {
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
        user: item,
      };
    })
  );

  const dealershipWatched = methods.watch('dealership');
  const dealershipUserWatched = methods.watch('dealershipUser');

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const onMenuOpenHandler = () => {
    setMenuOpened(true);

    if (
      editMode &&
      !isCSRSelectedDealershipDirty &&
      dealershipWatched?.value !== dealershipIdModalOpened &&
      dealershipWatched?.value === order?.job?.dealershipId
    ) {
      setDealershipIdModalOpened(dealershipWatched?.value ?? null);
      setModalOpened(true);
    }
  };

  useEffect(() => {
    if (isUserCSR) {
      dispatch(getDealerships());
      dispatch(getCSRUserList());
    }
  }, [isUserCSR]);

  useEffect(() => {
    if (order && dealershipId && !isCSRSelectedDealershipDirty) {
      const dealershipOption = dealershipOptions.find(
        (x) => x.value === dealershipId
      )!;

      const user =
        order.creatorId !== order.owner || order.creatorId === zeroGuid
          ? order.owner!
          : order.creator!;

      const ownerOrCreator = UtilService.mapObjectToSelectOptions(
        user,
        'id',
        'email'
      ) as SelectOptionProps;

      const dealershipUserOption = {
        ...ownerOrCreator,
        label: UtilService.getUsersConcatenatedNameOrEmail(user),
        user,
      };

      methods.reset({
        dealership: dealershipOption,
        dealershipUser: dealershipUserOption,
      });

      onDealershipChange!(dealershipOption);
      onDealershipUserChange!(dealershipUserOption);
    }
  }, [dealershipId, order]);

  useEffect(() => {
    const dirtyOrTouched = !!UtilService.dirtyOrTouched(
      methods.formState,
      'dealership'
    );

    if (!isCSRSelectedDealershipDirty && setIsCSRSelectedDealershipDirty) {
      setIsCSRSelectedDealershipDirty(dirtyOrTouched);
    }
  }, [methods.formState.dirtyFields.dealership]);

  useEffect(() => {
    const dirtyOrTouched = !!UtilService.dirtyOrTouched(
      methods.formState,
      'dealershipUser'
    );

    if (
      !isCSRSelectedDealershipUserDirty &&
      setIsCSRSelectedDealershipUserDirty
    ) {
      setIsCSRSelectedDealershipUserDirty(dirtyOrTouched);
    }
  }, [methods.formState.dirtyFields.dealershipUser]);

  useEffect(() => {
    onDealershipChange!(dealershipWatched);

    if (dealershipWatched) {
      setDealershipUsersLoading(true);
      dispatch(
        getDealershipUsers(dealershipWatched.value, setDealershipUsersLoading)
      );
    }
  }, [dealershipWatched]);

  useEffect(() => {
    if (isCSRSelectedDealershipDirty) {
      methods.setValue('dealershipUser', null);
      onDealershipUserChange!(null);
      dispatch(clearDealershipUsers());
    }
  }, [isCSRSelectedDealershipDirty, dealershipWatched]);

  useEffect(() => {
    if (dealershipUserWatched?.value) {
      onDealershipUserChange!(dealershipUserWatched);
    }
  }, [dealershipUserWatched]);

  const allUsersOptions = [
    ...dealershipUsersOptions.sort((a, b) =>
      UtilService.sortBy(a.label, b.label)
    ),
    ...(csrUserList ?? []),
  ];

  return (
    <WhiteBoxSection>
      <SectionHeading>Dealership</SectionHeading>

      <FormElement flexGrow>
        <FormLabel>Dealership</FormLabel>

        <Controller
          control={methods.control}
          name="dealership"
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              isDisabled={!canEdit}
              placeholder="Select a Dealership"
              isClearable
              menuIsOpen={menuOpened}
              onMenuOpen={onMenuOpenHandler}
              onChange={(val: SelectOptionProps) => {
                field.onChange(val);
                setMenuOpened(false);
              }}
              onMenuClose={() => {
                if (!modalOpened && menuOpened) setMenuOpened(false);
              }}
              aria-invalid={
                methods.formState.errors.dealership ? 'true' : 'false'
              }
              options={dealershipOptions.sort((a, b) =>
                UtilService.sortBy(a.label, b.label)
              )}
            />
          )}
        />

        <FormError
          label="Dealership"
          error={methods.formState.errors.dealership as FieldError}
          validationSchema={{ required: true }}
        />
      </FormElement>

      <FormElement flexGrow>
        <FormLabel>
          <Wrapper flex middle>
            Dealership User
            <Spacer w="10px" />
            {dealershipUsersLoading && <Loader size={16} noSpacing />}
          </Wrapper>
        </FormLabel>

        <Controller
          control={methods.control}
          name="dealershipUser"
          render={({ field }) => (
            <Select
              {...field}
              noOptionsMessage={() => (
                <P color={nevada} fontSize={14}>
                  There are no users in{' '}
                  <strong>{dealershipWatched?.label}</strong> dealership. Try
                  another one.
                </P>
              )}
              placeholder="Select a User"
              isDisabled={
                !dealershipWatched || !canEdit || dealershipUsersLoading
              }
              aria-invalid={
                methods.formState.errors.dealershipUser ? 'true' : 'false'
              }
              options={allUsersOptions}
            />
          )}
        />

        <FormError
          label="Dealership user"
          error={methods.formState.errors.dealershipUser as FieldError}
          validationSchema={{ required: true }}
        />
      </FormElement>

      <ConfirmationModal
        title="Are you sure?"
        opened={
          dealershipIdModalOpened === dealershipWatched?.value &&
          !isCSRSelectedDealershipDirty
        }
        confirm={(close) => {
          close();
          setModalOpened(false);
        }}
        shouldNotRenderCancelButton
        buttonText="Dimiss"
      >
        <P color={nevada}>
          Please note that changing the Dealership will affect the following
          fields:
        </P>

        <Spacer h="10px" />

        <ul>
          <li>- Job name</li>
          <li>- Dealership Info</li>
          <li>- Collaborators</li>
          <li>- Shipping address</li>
        </ul>
      </ConfirmationModal>
    </WhiteBoxSection>
  );
};

export default OrderDealershipSelection;

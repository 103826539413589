export enum TableColumns {
  DEALERSHIP = 'Dealership',
  JOB = 'Job',
  ROOM = 'Room',
  NUMBER = 'SerialNumber',
  TYPE = 'Type',
  ORDERED_BY = 'OrderedBy',
  STATUS = 'StatusId',
  SHIP_WEEK = 'ShipWeek',
  SUBMISSION_DATE = 'SubmittedOnUtc',
  ACK_DATE = 'AckDate',
  PRIORITY = 'Priority',
  GRADE = 'Grade',
  PRODUCT_LINE = 'ProductLine',
  TARGET_DATE = 'TargetDate',
  CREATED_DATE = 'CreatedOnUtc',
  REQUESTED_DATE = 'REQUESTED_DATE',
  MODIFIED_ON_UTC = 'ModifiedOnUtc',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TableTabsEnums {
  GENERAL = 'General',
  TIMELINE = 'Timeline',
  METRICS = 'Metrics',
  IMPORT_STYLE = 'ImportStyle',
}
